<template>

    <body>

        <!-- preloader -->
        <Loader> </Loader>
        <!-- preloader -->
        <div class="frame">
            <div class="site-wrapper overflow-hidden">

                <!-- Header Section -->
                <Front-Header> </Front-Header>

                <!-- Main Start-->
                <section class="hero_section height-half text-center">
                    <!--<img src="./../assets/front/image/contact-bg.jpg" style="position: absolute;">-->
                    <img :src="pages.image" style="position: absolute;">
                    <div class="container">
                        <!-- <h1 data-aos="fade-right" data-aos-duration="1200" class="aos-init aos-animate">{{ pages.title
                        }}</h1> -->
                        <!-- <p data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200"
                            class="aos-init aos-animate">{{ pages.short_description }}</p> -->
                    </div>
                </section>

                <div class="contact-us-page">
                    <!--Contact-us start-->
                    <div id="aboutUs">
                        <section class="contact_wrapper">
                            <div class="container">
                                <div class="row text-center">
                                    <h2 class="section__heading">{{ pages.title }}</h2>
                                </div>
                                <div class="row">
                                    <div v-html="pages.description"></div>

                                </div>

                            </div>
                        </section>
                    </div>
                </div>
                <!-- Main End-->
                <Front-Footer> </Front-Footer>

            </div>
        </div>

    </body>
</template>

<script>
import { mapGetters } from "vuex";
import VueMeta from 'vue-meta'
import Header from './Front-Header';
import Footer from './Front-Footer';
import inspired from './common/Inspired';
import Subscription from './common/Subscription';
import features from './common/Features';
import OurApp from './common/OurApp';
import Newsletter from './common/Newsletter';
import Topintro from './common/Topintro';
import Loader from './Loader';
import Vue from 'vue'

import { db } from '@/main'


import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
Vue.use(VueMeta)


export default {
    components: {
        'Front-Header': Header,
        'Front-Footer': Footer,
        'Subscription': Subscription,
        'Inspired': inspired,
        'features': features,
        'our-app': OurApp,
        'newsletter': Newsletter,
        'topintro': Topintro,
        'Loader': Loader,
        'VueSlickCarousel': VueSlickCarousel


    },

    computed: {
        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            user: "user",
        }),
    },

    data() {
        return {
            checkedValue: false,
            users: {},
            components: {},
            pages: {},
            slickOptions: {
                //options can be used from the plugin documentation
                slidesToShow: 3,
                infinite: true,
                meta_title: '',
                meta_description: '',
            },
            subscriptionss: [],
        }
    },
    metaInfo() {
        return {
            title: localStorage.getItem("meta_title"),
            meta: [
                { name: 'description', content: localStorage.getItem("meta_description") },
            ]
        }
    },
    created: function () {
        this.fetchPages();
    },

    methods: {
        next() {
            if (this.checkedValue == true) {
                var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
                var uid = localStorage.getItem("userSessionId");
                db.collection("affiliaateTerms")
                    .add({
                        status: '1',
                        user_id: uid,
                        created: date,
                        modified: date,
                    })
                    .then((doc) => {
                        console.log("Added Successfully!");
                        this.$router.push({ name: 'affiliateProfile' });
                    })
            } else if (this.checkedValue == false) {
                alert("Please accept terms and conditions...!");
            }
        },

        fetchPages() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            db.collection("pages").doc('QRJAVZl2HfPYBc5jUcO5')
                .get()
                .then((doc) => {
                    console.log('hhhh88');
                    console.log(doc.id, " => ", doc.data());
                    this.pages = doc.data();
                    this.meta_title = doc.data().meta_title;
                    this.meta_description = doc.data.meta_description;
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });


        }
    }
}

</script>
<style>
.form-check-input {
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.form-check-label {
    margin-left: 1%;
    font-size: 20px;
}

.btn-section {
    text-align: center;
    padding: 4% 0;
}

.accept-buttons {
    width: 15%;
    margin-top: 10px;
}

/* afflialate */

@import "../../assets/affiliate/css/style.css";

@import "../../assets/affiliate/instruction-css/animate.css";

/* @import "../../assets/affiliate/instruction-css/bootstrap.min.css"; */

@import "../../assets/affiliate/instruction-css/font-awesome.min.css";

@import "../../assets/affiliate/instruction-css/icofont.min.css";

@import "../../assets/affiliate/instruction-css/responsive.css";

/* @import "../../assets/affiliate/css/affiliate.style.css"; */
a {
    color: blue;
}
p{
    color: #575757;
}
</style>
